import { ProxyTypeCard } from "@/ui/browse/ProxyTypeCard";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { DEFAULT_CHECKOUT_PATHNAME } from "@/utils/formHref";
import { useRouter } from "next/router";
import { stringifyBase64 } from "@/hooks/useQueryParamState";
import DialogCloseButton from "@/ui/dialogs/DialogCloseButton";
import Image from "next/image";
import { ParsedUrlQueryInput } from "querystring";
import { useState } from "react";
import { Highlighter } from "@/ui/Highlighter";
import useIsFlagVariantTest from "@/hooks/useIsFlagVariantTest";

const PriceBox = ({
  title,
  numProxies,
  bandwidth,
  price,
  query,
  imgName,
  discountPrice,
}: {
  title: string;
  numProxies: string;
  bandwidth: string;
  price: string;
  query: string | ParsedUrlQueryInput | null | undefined;
  imgName: string;
  discountPrice?: string;
}) => {
  const router = useRouter();
  return (
    <ProxyTypeCard
      selected={false}
      sx={{ p: 3, borderRadius: 2, cursor: "pointer" }}
      onClick={() =>
        router.push({
          pathname: DEFAULT_CHECKOUT_PATHNAME,
          query,
        })
      }
      inverted
    >
      <Stack spacing={2}>
        <Image
          src={`/${imgName}-icon.svg`}
          width={34}
          height={34}
          alt={imgName}
        />
        <Typography variant="h6">{title}</Typography>
        <Box>
          <Typography variant="subtitle2">{numProxies}</Typography>
          <Typography variant="subtitle2">{bandwidth}</Typography>
        </Box>

        <Stack direction="row" alignItems="center" spacing={2}>
          {discountPrice ? (
            <Typography
              variant="h6"
              color="primary.dark"
              fontWeight={400}
              sx={{ textDecoration: "line-through" }}
            >
              {price}
            </Typography>
          ) : null}
          <Typography variant="h4" fontWeight="bold" color="primary.dark">
            {discountPrice ?? price}/mo
          </Typography>
        </Stack>
      </Stack>
    </ProxyTypeCard>
  );
};

export default function MostPopularPlansModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [isYearly, setIsYearly] = useState(false);

  const inExp = useIsFlagVariantTest("most_popular_plans_modal_add_yearly");

  const extraParams = isYearly ? { term: "yearly" } : {};

  const data = [
    {
      imgName: "datacenter",
      title: "Proxy Server",
      numProxies: "100 Proxies",
      bandwidth: "250 GB",
      price: "$2.99",
      discountPrice: isYearly ? "$1.99" : undefined,
      query: {
        ...extraParams,
        source: "direct-links-banner",
        customizeParams: stringifyBase64({
          planCategory: "shared",
          planSubCategory: "default",
          numberOfProxies: 100,
          bandwidth: 250,
          subUser: 3,
          proxyReplacements: 10,
        }),
      },
    },
    {
      imgName: "static-residential",
      title: "Static Residential",
      numProxies: "20 Proxies",
      bandwidth: "250 GB",
      price: "$6.00",
      discountPrice: isYearly ? "$4.00" : undefined,
      query: {
        ...extraParams,
        source: "direct-links-banner",
        customizeParams: stringifyBase64({
          planCategory: "shared",
          planSubCategory: "isp",
          numberOfProxies: 20,
          bandwidth: 250,
          subUser: 3,
          proxyReplacements: 10,
        }),
      },
    },
    {
      imgName: "residential",
      title: "Residential",
      numProxies: "30 million IPs/month",
      bandwidth: "1 GB",
      price: "$7.00",
      discountPrice: isYearly ? "$4.67" : undefined,
      query: {
        ...extraParams,
        source: "direct-links-banner",
        customizeParams: stringifyBase64({
          planCategory: "shared",
          planSubCategory: "residential",
          numberOfProxies: 30000000,
          bandwidth: 1,
          subUser: 3,
          proxyReplacements: 0,
        }),
      },
    },
  ];

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle sx={{ pb: 5, pr: 6, textAlign: "center" }}>
        Try one of our most popular plans
      </DialogTitle>
      {inExp ? (
        <Stack direction={"column"} alignItems={"center"}>
          <FormControlLabel
            control={
              <Switch
                data-testid="switch"
                checked={isYearly}
                onChange={() => {
                  setIsYearly(!isYearly);
                }}
              />
            }
            label={
              <Typography>
                Pay Yearly{" "}
                <Highlighter
                  size="medium"
                  severity="secondary"
                  innerVariant="filled"
                  sx={{ ml: 2 }}
                >
                  Save 30%
                </Highlighter>
              </Typography>
            }
          />
        </Stack>
      ) : null}
      <DialogContent>
        <Box sx={{ pb: 5 }}>
          <Grid container direction="row" spacing={2}>
            {data.map((item, index) => (
              <Grid item md={4} xs={12} key={index}>
                <PriceBox {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
