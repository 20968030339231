import { Box, Fab, Typography, ThemeProvider } from "@mui/material";
import React from "react";
import ImgHelper from "@/components/common/ImgHelper";
import { useAppTheme } from "../theme";
import useIntercom from "@/hooks/useIntercom";

//Used by intercom for openind onboarding checklist using intercom tooltips
export default function OnboardingChecklistFAB() {
  const { showSpace, startChecklist } = useIntercom();

  return (
    <ThemeProvider theme={useAppTheme("light")}>
      <Fab
        id="get-started-button"
        data-testid="get-started-button"
        variant="extended"
        size="small"
        color={"primary"}
        sx={{ position: "fixed", bottom: 40, right: 40 }}
        onClick={() => {
          showSpace("tasks");
          startChecklist(35862923);
        }}
      >
        <Box width={30} marginRight={1}>
          <ImgHelper
            alt="webshare-logo"
            src="/companylogos/webshare-white-logo.png"
          />
        </Box>
        <Typography
          color="white"
          fontWeight="bold"
          fontSize="small"
          variant="body1"
          textTransform={"none"}
        >
          Get Started
        </Typography>
      </Fab>
    </ThemeProvider>
  );
}
