import {
  Card,
  Box,
  Typography,
  Link,
  Tooltip,
  Stack,
  Skeleton,
} from "@mui/material";
import { useMemo } from "react";
import { StatsErrorType } from "@/types/StatsErrorType";
import CircularProgressWithLabel from "../progress/CircularProgressWithLabel";
import { ContentHolder, StatsHolder } from "./styles";
import { NextLink } from "@/components/common/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FlexBox } from "../common/styles";
import InfoCard from "@/ui/InfoCard";
import useSWR from "swr";
import { formBackendURL } from "@/utils/formURL";
import { fetcher } from "@/utils/fetcher";
import { ProxyConfigurationType } from "@/types/ProxyConfigurationTypes";
import { articles } from "@/utils/intercom";
import useIntercom from "@/hooks/useIntercom";

export default function ErrorRatio({
  errorReasons,
  requestsTotal,
}: {
  errorReasons?: StatsErrorType[];
  requestsTotal?: number;
}) {
  const { showArticle } = useIntercom();
  const { data: config, isLoading: isLoadingProxyConfig } =
    useSWR<ProxyConfigurationType>(formBackendURL("/proxy/config/"), fetcher);

  const errorData = useMemo(() => {
    if (!errorReasons?.length || !requestsTotal)
      return { configErrorRate: 0, connectionErrorRate: 0 };

    let numConfigErrors = 0;
    let numConnectionErrors = 0;

    errorReasons.forEach((errorReason: StatsErrorType) => {
      const { type, count } = errorReason;
      if (type === "configuration") numConfigErrors += count;
      if (type === "connection") numConnectionErrors += count;
    });

    const configErrorRate = numConfigErrors / requestsTotal;
    const connectionErrorRate = numConnectionErrors / requestsTotal;

    return { configErrorRate, connectionErrorRate };
  }, [errorReasons, requestsTotal]);

  const showPlaceholder = config && config.is_proxy_used === false;
  const showLoader = isLoadingProxyConfig;

  return (
    <Card sx={{ p: 3, height: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Typography variant="h6" color="text.primary">
          Error Ratio
        </Typography>
        <Link
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            showArticle(articles.proxyUsage);
          }}
        >
          <Typography variant="body1">Learn More</Typography>
        </Link>
      </Stack>

      {showLoader ? (
        <Skeleton
          data-testid="error-ratio-loader"
          variant="rectangular"
          height={150}
        />
      ) : showPlaceholder ? (
        <InfoCard
          testId="error-ratio-placeholder"
          description="No data to show"
          backgroundColor="grey.100"
          elevation={0}
          imageSrc="/pieChart"
          imageAlt="Empty Pie Chart"
          visualSize={50}
        >
          <NextLink href="/proxy/list" passHref>
            <Link component="span" underline="always" color="primary.main">
              Go to Proxy list
            </Link>
          </NextLink>
        </InfoCard>
      ) : (
        <Box sx={{ py: 2, px: 3 }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }}>
              <StatsHolder>
                <CircularProgressWithLabel
                  size={40}
                  value={errorData.configErrorRate * 100}
                />
                <ContentHolder>
                  <FlexBox>
                    <Typography variant="caption" color="text.secondary">
                      Config Error Rate
                    </Typography>
                    <Tooltip
                      title="Proxy configuration error rate. Can be resolved by updating your proxy configuration."
                      arrow
                    >
                      <InfoOutlinedIcon
                        sx={{ color: "action.active", ml: 2 }}
                        fontSize="small"
                      />
                    </Tooltip>
                  </FlexBox>

                  <Link
                    component={NextLink}
                    href="/stats/error"
                    passHref
                    data-testid="configErrorRate"
                    variant="h6"
                    underline="hover"
                    sx={{ cursor: "pointer" }}
                  >
                    {Number(errorData.configErrorRate).toLocaleString(
                      undefined,
                      {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }
                    )}
                  </Link>
                </ContentHolder>
              </StatsHolder>
            </Box>

            <Box sx={{ flex: 1 }}>
              <StatsHolder>
                <CircularProgressWithLabel
                  size={40}
                  value={errorData.connectionErrorRate * 100}
                />
                <ContentHolder>
                  <FlexBox>
                    <Typography variant="caption" color="text.secondary">
                      Connection Error Rate
                    </Typography>
                    <Tooltip title="Proxy connectivity error rate." arrow>
                      <InfoOutlinedIcon
                        sx={{ color: "action.active", ml: 2 }}
                        fontSize="small"
                      />
                    </Tooltip>
                  </FlexBox>
                  <Link
                    component={NextLink}
                    href="/stats/error"
                    passHref
                    data-testid="connectionErrorRate"
                    variant="h6"
                    underline="hover"
                    sx={{ cursor: "pointer" }}
                  >
                    {Number(errorData.connectionErrorRate).toLocaleString(
                      undefined,
                      {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }
                    )}
                  </Link>
                </ContentHolder>
              </StatsHolder>
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  );
}
