import { Button, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import HeadTitleKeyword from "../../src/components/common/HeadTitleKeyword";
import CustomDateTimeRangePicker from "../../src/components/pickers/CustomDateTimeRangePicker";
import useStats from "../../src/hooks/useStats";
import BandwidthUsage from "../../src/components/BandwidthUsage";
import ProxyUsageChart from "../../src/components/charts/ProxyUsageChart";
import ErrorRatio from "../../src/components/ErrorRatio";
import { gbToBytes } from "../../src/utils/conversionUtils";
import { useSession } from "../../src/security/RouteGuards";
import { getBandwidthUsageState } from "../../src/utils/getBandwidthUsageState";
import useSubscriptionStats from "../../src/hooks/useSubscriptionStats";
import ProxyUsageStats from "@/components/stats/ProxyUsageStats";
import ProfilePreferencesRatingSnackbar from "@/components/rating/ProfilePreferencesRatingSnackbar";
import HelpArticle from "@/components/buttons/HelpArticle";
import PlanMini from "@/components/plan/PlanMini";
import InfoCard from "@/ui/InfoCard";
import { formBackendURL } from "@/utils/formURL";
import { fetcher } from "@/utils/fetcher";
import useSWR from "swr";
import { ProxyConfigurationType } from "@/types/ProxyConfigurationTypes";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import OnboardingChecklistFAB from "@/ui/OnboardingChecklistFAB";

import { useSubscriptionSafeTimeStamps } from "@/hooks/useSubscriptionSafeTimestamps";

import WelcomeDialog from "./WelcomeDialog";
import BandwidthUsageBanner from "@/components/banners/BandwidthUsageBanner";

import MostPopularPlans from "@/components/cards/MostPopularPlans";
import useIsFlagVariantTest from "@/hooks/useIsFlagVariantTest";
import { ColoredCard } from "@/ui/ColoredCard";
import Image from "next/image";
import { getCookie } from "cookies-next";
import { CHROME_WEBSTORE_LINK } from "@/components/banners/ChromeExtensionBanner";

export default function Dashboard() {
  const { plan } = useSession();

  const theme = useTheme();

  const isDark = theme.palette.mode === "dark";
  const isSM = useMediaQuery("(max-width:409px)");

  const inPreviewListExp = useIsFlagVariantTest(
    "dashboard_show_preview_of_proxy_list"
  );

  const inExtensionBannerExp = useIsFlagVariantTest(
    "dashboard_add_install_extension_widget"
  );

  const hasExtension = !!getCookie("webshare_extension_version");

  const isFreePlan = plan?.proxy_type === "free";

  const {
    lteSafe: timestampLte,
    gteSafe: timestampGte,
    minDate,
    maxDate,
  } = useSubscriptionSafeTimeStamps();

  const { data: config, isLoading: isLoadingProxyConfig } =
    useSWR<ProxyConfigurationType>(formBackendURL("/proxy/config/"), fetcher);

  const {
    stats: { data: statsData },
    aggregateStats: {
      data: aggregateStatsData,
      isValidating: isAggregateStatsValidating,
    },
  } = useStats({ timestampLte, timestampGte });

  const isAggregateStatsLoading =
    isAggregateStatsValidating && !aggregateStatsData;

  const { bandwidth_limit } = plan || {};

  const {
    requests_total,
    bandwidth_total,
    number_of_proxies_used,
    bandwidth_projected,
  } = aggregateStatsData || {};

  const subscriptionStats = useSubscriptionStats();

  const usageState = getBandwidthUsageState({
    aggregateStatsData: subscriptionStats,
    plan,
  });

  const showPlaceholder = config && config.is_proxy_used === false;

  const shouldShowPreviewListBanner =
    isFreePlan && inPreviewListExp && showPlaceholder && !isLoadingProxyConfig;

  const shouldHideProxyUsage =
    (isFreePlan && inPreviewListExp && showPlaceholder) || isLoadingProxyConfig;

  const shouldShowExtensionBanner =
    !hasExtension && !isLoadingProxyConfig && inExtensionBannerExp;

  return (
    <>
      <HeadTitleKeyword title="Dashboard" />
      <ProfilePreferencesRatingSnackbar />
      <Grid container direction="row" spacing={3} sx={{ p: 3, pt: 0 }}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.primary">
              Dashboard
            </Typography>
          </Box>
        </Grid>

        {usageState ? (
          <Grid item xs={12}>
            <BandwidthUsageBanner
              usageState={usageState}
              source="dashboard-upgrade"
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={isFreePlan ? 6 : 12}>
              <PlanMini />
            </Grid>
            {isFreePlan ? (
              <Grid item xs={12} md={6}>
                <MostPopularPlans />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {isLoadingProxyConfig ? (
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Skeleton
                data-testid="proxy-usage-loader"
                variant="rectangular"
                height={250}
              />
            </Card>
          </Grid>
        ) : null}

        {shouldShowPreviewListBanner || shouldShowExtensionBanner ? (
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={3}
              sx={{ height: "100%" }}
            >
              {shouldShowPreviewListBanner ? (
                <Box flex={1} sx={{ height: "100%" }}>
                  <Card sx={{ p: 3, height: "100%" }}>
                    <ColoredCard
                      type="primary"
                      sx={{ position: "relative", height: "100%" }}
                    >
                      <Box maxWidth={`calc(100% - 87px)`}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 1 }}
                          data-testid="preview-list-exp"
                        >
                          🎉 Congratulations!
                        </Typography>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{ mb: 3 }}
                        >
                          You have 10 free proxies included in your plan! Get
                          started for free.
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          href="proxy/list"
                        >
                          View My Proxy List
                        </Button>
                      </Box>
                      <Image
                        style={{
                          position: "absolute",
                          right: -30,
                          top: -1,
                          bottom: -1,
                          margin: "auto",
                        }}
                        alt={"list-visuals"}
                        src={`/proxy-list-visual-${isDark ? "dark" : "light"}.png`}
                        width={117}
                        height={129}
                      />
                    </ColoredCard>
                  </Card>
                </Box>
              ) : null}

              {shouldShowExtensionBanner ? (
                <Box flex={1} sx={{ height: "100%" }}>
                  <Card sx={{ p: 3, height: "100%" }}>
                    <ColoredCard
                      type="info"
                      sx={{ position: "relative", height: "100%" }}
                    >
                      <Box maxWidth={`calc(100% - ${isSM ? "87px" : "127px"})`}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 1 }}
                          data-testid="chrome-extension-exp"
                        >
                          Install Chrome Extension
                        </Typography>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{ mb: 3 }}
                        >
                          Improve your browser experience with our free chrome
                          extension!
                        </Typography>
                        <Button
                          variant="contained"
                          color="info"
                          size="small"
                          href={CHROME_WEBSTORE_LINK}
                          target="blank"
                        >
                          Add extension for free
                        </Button>
                      </Box>
                      <Image
                        style={{
                          position: "absolute",
                          right: isSM ? -70 : -30,
                          margin: "auto",
                          ...(isSM ? { bottom: -10, top: -1 } : { top: 9 }),
                        }}
                        alt={"list-visuals"}
                        src={`/extension-visual-${isDark ? "dark" : "light"}.png`}
                        width={157}
                        height={190}
                      />
                    </ColoredCard>
                  </Card>
                </Box>
              ) : null}
            </Stack>
          </Grid>
        ) : null}

        {shouldHideProxyUsage ? null : (
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack
                direction={{
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                }}
                justifyContent="space-between"
                spacing={3}
                sx={{ mb: 3 }}
              >
                <Typography variant="h6">
                  Proxy Usage
                  <HelpArticle id="proxyUsage" />
                </Typography>
                {isLoadingProxyConfig || showPlaceholder ? null : (
                  <CustomDateTimeRangePicker
                    withPresets
                    timestampLte={timestampLte}
                    timestampGte={timestampGte}
                    minDate={minDate}
                    maxDate={maxDate}
                    size="small"
                    justifyContent="right"
                    minutesInBetween={1}
                  />
                )}
              </Stack>

              {showPlaceholder ? (
                <InfoCard
                  testId="proxy-usage-placeholder"
                  title="No data to show"
                  imageSrc="/barChart"
                  imageAlt="Empty bar chart"
                  visualSize={50}
                  description="The data will be automatically updated once you start using proxies."
                  backgroundColor="grey.100"
                  elevation={0}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    href="proxy/list"
                  >
                    Go To Proxy List
                  </Button>
                </InfoCard>
              ) : (
                <Box>
                  <ProxyUsageStats
                    isAggregateStatsLoading={isAggregateStatsLoading}
                    requests_total={requests_total}
                    bandwidth_limit={bandwidth_limit}
                    bandwidth_total={bandwidth_total}
                    number_of_proxies_used={number_of_proxies_used}
                  />
                  <ProxyUsageChart
                    timestampGte={timestampGte}
                    timestampLte={timestampLte}
                    stats={statsData}
                    maxY={Math.max(
                      bandwidth_total ?? 0,
                      bandwidth_projected ?? 0,
                      gbToBytes(bandwidth_limit ?? 0)
                    )}
                    primaryColor={theme.palette[usageState ?? "primary"].main}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        )}

        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} lg={7} xl={7}>
            <BandwidthUsage />
          </Grid>
          <Grid item xs={12} lg={5} xl={5}>
            <ErrorRatio
              errorReasons={aggregateStatsData?.error_reasons}
              requestsTotal={aggregateStatsData?.requests_total}
            />
          </Grid>
        </Grid>
      </Grid>
      <OnboardingChecklistFAB />
      <WelcomeDialog />
    </>
  );
}
