import { CardActions, styled } from "@mui/material";
import Card, { CardProps } from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

export const StyledCardHeader = styled(CardHeader)(() => ({
  ".MuiCardHeader-action": {
    margin: "auto",
  },
}));

export const StyledCard = styled((props: CardProps) => (
  <Card {...props} sx={{ p: 2 }}>
    {props.children}
  </Card>
))(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
  marginTop: "auto",
}));

export const StyledCardv2 = styled((props: CardProps) => (
  <Card {...props} sx={{ p: 3 }}>
    {props.children}
  </Card>
))(() => ({
  height: "100%",
}));
