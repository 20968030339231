import {
  Box,
  IconButton,
  IconContainerProps,
  Rating,
  Snackbar,
  Typography,
  styled,
  Card,
  useTheme,
  ThemeProvider,
  CardActions,
} from "@mui/material";
import { useAppTheme } from "../theme";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SentimentVerySatisfiedOutlined from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import SentimentSatisfiedOutlined from "@mui/icons-material/SentimentSatisfiedOutlined";
import SentimentNeutralOutlined from "@mui/icons-material/SentimentNeutralOutlined";
import SentimentDissatisfiedOutlined from "@mui/icons-material/SentimentDissatisfiedOutlined";

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentDissatisfiedOutlined color="error" fontSize="large" />,
    label: "Do not recommend",
  },
  2: {
    icon: <SentimentNeutralOutlined color="warning" fontSize="large" />,
    label: "Neutral",
  },
  3: {
    icon: <SentimentSatisfiedOutlined color="info" fontSize="large" />,
    label: "Recommend",
  },
  4: {
    icon: <SentimentVerySatisfiedOutlined color="primary" fontSize="large" />,
    label: "Strongly recommend",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <Box sx={{ p: 1 }} {...other}>
      {customIcons[value].icon}
    </Box>
  );
}
interface RatingSnackbarProps {
  open: boolean;
  onRate: (value: number, label: string) => void;
  onDismiss: () => void;
}

export default function RatingSnackbar({
  open,
  onRate,
  onDismiss,
}: RatingSnackbarProps) {
  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const { palette } = useTheme();

  const isDarkMode = palette.mode === "dark";

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onDismiss();
  };

  return (
    <ThemeProvider theme={useAppTheme(isDarkMode ? "light" : "dark")}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Card data-testid="rating-card">
          <CardActions>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="body1"
                  minWidth={"200px"}
                  sx={{ pl: 1, pr: 3 }}
                >
                  Would you recommend Webshare to your colleagues or friends?
                </Typography>

                <StyledRating
                  name="highlight-selected-only"
                  max={4}
                  size="large"
                  IconContainerComponent={IconContainer}
                  getLabelText={(value: number) => customIcons[value].label}
                  highlightSelectedOnly
                  sx={{ pr: 2 }}
                  onChange={(_event, newValue) => {
                    if (newValue) {
                      onRate(newValue, customIcons[newValue].label);
                    }
                  }}
                />
              </Box>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                data-testid="rating-dismiss-icon-button"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </CardActions>
        </Card>
      </Snackbar>
    </ThemeProvider>
  );
}
