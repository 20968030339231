import { TProxyPlanKey } from "@/components/newcustomize/hooks/useProxyPlanKey";
import useUpgradeToReplaceExp from "@/components/newcustomize/hooks/useUpgradeToReplaceExp";
import { useMemo } from "react";

type TItems = {
  id: TProxyPlanKey;
  value: string;
  title: string;
  price: number;
  description: string;
};

export default function useProxyTabs() {
  const inUpgradeToReplaceExp = useUpgradeToReplaceExp();

  const items: TItems[] = useMemo(() => {
    return [
      {
        id: "shared_default",
        value: "datacenter",
        title: "Datacenter",
        price: 2.99,
        description: "Fast & Affordable datacenter proxies.",
      },
      {
        id: "shared_isp",
        value: "staticResidential",
        title: "Static Residential",
        price: 6,
        description: "Residential proxies with up to unlimited bandwidth.",
      },
      ...(!inUpgradeToReplaceExp
        ? ([
            {
              id: "shared_residential",
              value: "residential",
              title: "Residential",
              price: 7,
              description: "Up to 30 million residential IPs.",
            },
            {
              id: "shared_verified",
              value: "verified",
              title: "Verified",
              price: 150,
              description:
                "Get proxies that won’t be blocked on Google Search.",
            },
          ] as TItems[])
        : []),
    ];
  }, [inUpgradeToReplaceExp]);

  return { items };
}
