import simplur from "simplur";
import {
  PlanType,
  _Plan_MainPropsType,
  _Plan_RefreshesPropsType,
} from "../types/PlanTypes";
import { isObjKey } from "./typeCheckUtils";
import logToSentry from "./logToSentry";

const hideSingular = (qty: number) => (qty === 1 ? null : qty.toLocaleString());

export default function makeSubTypeLabel({
  plan,
  prependProxyCount,
}: {
  plan: PlanType | (_Plan_MainPropsType & _Plan_RefreshesPropsType);
  prependProxyCount?: boolean;
}) {
  const { proxy_type, proxy_subtype } = plan;

  let proxySubtype =
    proxy_subtype === "verified"
      ? "default"
      : proxy_subtype === "verifiedIsp"
        ? "isp"
        : proxy_subtype;

  proxySubtype = proxySubtype === "non-premium" ? "default" : proxySubtype;

  if (
    (proxy_type === "dedicated" || proxy_type === "semidedicated") &&
    proxySubtype === "default"
  ) {
    proxySubtype = "premium";
  }

  const mapping = {
    free_default: (count: number) =>
      simplur`${[count, hideSingular]} Free Prox[y|ies]`,
    shared_default: (count: number) =>
      simplur`${[count, hideSingular]} Proxy Server[|s]`,
    semidedicated_premium: (count: number) =>
      simplur`${[count, hideSingular]} Private Proxy Server[|s]`,
    dedicated_premium: (count: number) =>
      simplur`${[count, hideSingular]} Dedicated Proxy Server[|s]`,
    shared_isp: (count: number) =>
      simplur`${[count, hideSingular]} Static Residential Prox[y|ies]`,
    semidedicated_isp: (count: number) =>
      simplur`${[count, hideSingular]} Private Static Residential Prox[y|ies]`,
    dedicated_isp: (count: number) =>
      simplur`${[
        count,
        hideSingular,
      ]} Dedicated Static Residential Prox[y|ies]`,
    shared_residential: (count: number) =>
      simplur`${[count, hideSingular]} Residential Prox[y|ies]`,
  };

  const key = `${proxy_type}_${proxySubtype}` as keyof typeof mapping;

  if (!isObjKey(key, mapping)) {
    logToSentry("invalid plan name", {
      extras: [["errorData", JSON.stringify({ proxy_type, proxy_subtype })]],
    });
    return "Plan";
  }

  const count = prependProxyCount ? plan.proxy_count : 1;
  return mapping[key](count);
}
