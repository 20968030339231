import useUrlState, {
  useDeleteQueryParams,
  prepareObjectForQuery,
} from "@/hooks/useUrlState";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ColoredCard } from "@/ui/ColoredCard";
import Image from "next/image";
import Inset from "@/ui/spacing/Inset";
import {
  Grid,
  Typography,
  Dialog,
  Card,
  CardContent,
  useTheme,
  Button,
  CardActions,
  CardActionArea,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";

import useIntercom from "@/hooks/useIntercom";
import { useRouter } from "next/router";
import { Highlighter } from "@/ui/Highlighter";
import useProxyTabs from "@/components/newcustomize/ProxyDetails/ProxyTabs/hooks/useProxyTabs";
import { typedSplit } from "@/utils/textUtils";

/**
 * DialogContentTitle component
 * @description
 * It is responsive and will render the typography in different sizes based on
 * the screen size and the subtitle and description will be rendered together
 * in a single line on smaller screens
 *
 * @example
 * <DialogContentTitle
 *   title="Welcome to Webshare! 🎉"
 *   subtitle="Congratulations! You have 10 free proxies included in your plan!"
 *   description="Get started for free or explore our plans below."
 * />
 */
function DialogContentTitle({
  title,
  subtitle,
  description,
  testid,
}: {
  title: string;
  description: string;
  subtitle?: string;
  testid?: string;
}) {
  const theme = useTheme();
  const isUpFromMedium = useMediaQuery(theme.breakpoints.up("md"));
  const bothTogether = `${subtitle} ${description}`;

  return (
    <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
      <Stack
        data-testid={testid}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ maxWidth: "520px" }}
      >
        {title ? (
          <Typography
            variant={isUpFromMedium ? "h4" : "h5"}
            fontWeight="bold"
            textAlign="center"
          >
            {title}
          </Typography>
        ) : null}
        <Typography
          variant={isUpFromMedium ? "body1" : "body2"}
          color="text.secondary"
          textAlign="center"
          style={{ whiteSpace: "pre-line" }}
        >
          {isUpFromMedium ? (subtitle ? `${subtitle}\n` : "") : bothTogether}
          {isUpFromMedium ? description : ""}
        </Typography>
      </Stack>
    </Stack>
  );
}

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  "&:last-child": {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

const iconSize = 28;
const easyStartItems = [
  {
    icon: "Proxy Server",
    title: "10",
    subtitle: "premium proxies",
  },
  {
    icon: "Bandwidth",
    title: "1 GB",
    subtitle: "/month bandwidth",
  },
  {
    icon: "Countries",
    title: "Multiple",
    subtitle: "countries",
  },
];
function FreeBenefits({
  items,
}: {
  items: {
    icon: string;
    title: string;
    subtitle: string;
  }[];
}) {
  const theme = useTheme();
  return (
    <Stack>
      <Grid container spacing={2}>
        {items.map((benefit) => (
          <Grid item xs={12} md={4} key={benefit.title}>
            <Card>
              <StyledCardContent>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Image
                    alt={benefit.icon}
                    src={`/${theme.palette.mode}/${benefit.icon}.svg`}
                    width={iconSize}
                    height={iconSize}
                    style={{ margin: "0 auto" }}
                  />
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography fontWeight="bold" variant="body1">
                      {benefit.title}
                    </Typography>
                    <Typography variant="body2">{benefit.subtitle}</Typography>
                  </Stack>
                </Stack>
              </StyledCardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
function EasyStart() {
  const router = useRouter();
  return (
    <Stack>
      <Typography
        variant="overline"
        fontWeight="bold"
        color="text.secondary"
        sx={{ pl: 4 }}
      >
        Easy start with Webshare
      </Typography>
      <ColoredCard type="primary">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <Typography variant="h6" fontWeight="bold">
              Free Plan
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Includes
            </Typography>
          </Stack>
          <FreeBenefits items={easyStartItems} />
          <CardActions sx={{ px: 0, pb: 1 }}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                router.push("/proxy/list");
              }}
            >
              {"Let's Get Started"}
            </Button>
          </CardActions>
        </Stack>
      </ColoredCard>
    </Stack>
  );
}

function PaidBenefits({
  items,
}: {
  items: {
    icon: string;
    title: string;
    subtitle: string;
    onClick: () => void;
    highlight?: string;
  }[];
}) {
  return (
    <Stack>
      <Grid container spacing={2}>
        {items.map((benefit) => (
          <Grid item xs={12} key={benefit.title}>
            <Card>
              <CardActionArea sx={{ py: 2, px: 3 }} onClick={benefit.onClick}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Image
                    alt={benefit.icon}
                    src={`/light/${benefit.icon}.svg`}
                    width={iconSize}
                    height={iconSize}
                    style={{ margin: "0 auto" }}
                  />
                  {/* Label and > */}
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ flexGrow: 1 }}
                  >
                    {/* Top Text and Bottom Text */}
                    <Stack>
                      {/* Title and Highlighter */}
                      <Stack direction="row" spacing={2}>
                        <Typography fontWeight={"bold"} variant="body1">
                          {benefit.title}
                        </Typography>
                        {benefit.highlight ? (
                          <Highlighter
                            severity="primary"
                            size="small"
                            innerVariant={"opacity"}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {benefit.highlight}
                          </Highlighter>
                        ) : null}
                        {/* End Top Title and Highlighter */}
                      </Stack>
                      {/* Subtitle */}
                      <Typography variant="body2">
                        {benefit.subtitle}
                      </Typography>
                      {/* End Top Text and Bottom Text */}
                    </Stack>
                    <ChevronRightIcon color="disabled" />
                    {/* Label and > */}
                  </Stack>
                  {/* End Image and Label */}
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
function PaidPlans() {
  const router = useRouter();
  const { items } = useProxyTabs();

  const mapped = items.map((item) => {
    const [proxyType, proxySubtype] = typedSplit(item.id, "_");
    return {
      icon: item.title,
      title: item.title,
      subtitle: `From $${item.price.toFixed(2)}/mo`,
      highlight: item.value === "Verified" ? "NEW!" : undefined,
      onClick: () => {
        router.push({
          pathname: "/subscription/customize",
          query: prepareObjectForQuery({
            proxyType,
            proxySubtype,
            source: "welcome-dialog",
          }),
        });
      },
    };
  });
  const filtered = mapped.filter((item) => item.title !== "Verified");
  return (
    <Stack sx={{ height: "100%" }}>
      <Typography
        variant="overline"
        fontWeight="bold"
        color="text.secondary"
        sx={{ pl: 4 }}
      >
        Looking for more possibilities?
      </Typography>
      <ColoredCard type="warning" sx={{ height: "100%" }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <Typography variant="body1" fontWeight="bold">
              Our paid plans
            </Typography>
          </Stack>
          <PaidBenefits items={filtered} />
        </Stack>
      </ColoredCard>
    </Stack>
  );
}

function Tutorial() {
  const { showSpace, startChecklist } = useIntercom();
  const theme = useTheme();
  const isUpFromMedium = useMediaQuery(theme.breakpoints.up("md"));
  const height = isUpFromMedium ? 190 : 152;
  const width = height * 2;
  function openTutorial() {
    showSpace("tasks");
    startChecklist(35862923);
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography
        variant="overline"
        fontWeight="bold"
        color="text.secondary"
        sx={{ pl: 4 }}
      >
        Don&apos;t know how to use proxies?
      </Typography>
      <ColoredCard type="info" sx={{ height: "100%" }}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight="bold">
              Tutorial Video
            </Typography>
          </Stack>
          <CardActionArea onClick={openTutorial}>
            <Image
              alt="Tutorial"
              src="/tutorial.png"
              layout="responsive"
              width={width}
              height={height}
            />
          </CardActionArea>
        </Stack>
      </ColoredCard>
    </Stack>
  );
}

export default function WelcomeDialog() {
  const [isOpen] = useUrlState("showWelcomeDialog");
  const deleteQueryParams = useDeleteQueryParams();
  const theme = useTheme();
  const isUpFromMedium = useMediaQuery(theme.breakpoints.up("md"));

  function handleClose() {
    deleteQueryParams(["showWelcomeDialog"]);
  }
  return (
    <Dialog
      data-testid="listed-countries-dialog"
      maxWidth="md"
      fullWidth={true}
      open={!!isOpen}
      onClose={handleClose}
    >
      <Inset padding={isUpFromMedium ? 5 : 3}>
        <Stack spacing={5}>
          <DialogContentTitle
            title="Welcome to Webshare! 🎉"
            subtitle="Congratulations! You have 10 free proxies included in your plan!"
            description="Get started for free or explore our plans below."
          />
          <EasyStart />
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ pr: isUpFromMedium ? 2 : 0, pb: isUpFromMedium ? 0 : 2 }}
            >
              <PaidPlans />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ pl: isUpFromMedium ? 2 : 0, pt: isUpFromMedium ? 0 : 2 }}
            >
              <Tutorial />
            </Grid>
          </Grid>
        </Stack>
      </Inset>
    </Dialog>
  );
}
