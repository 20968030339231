import { ProfilePreferencesType } from "@/types/ProfilePreferencesType";
import TimezoneUtils from "@/utils/TimezoneUtils";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { differenceInDays } from "date-fns";
import RatingSnackbar from "@/ui/RatingSnackbar";
import ThankYouSnackbar from "@/ui/ThankYouSnackbar";
import { patchPreferences } from "@/api/preferences";
import { useEvents, PostHogEvents } from "@/tracking/useEvents";
import { Button } from "@mui/material";
import animationData from "../../../public/lotties/trustPilot.json";

import InfoCardDialog from "@/ui/dialogs/InfoCardDialog";
import { LottieProps } from "react-lottie-player";

const lottieOptions: LottieProps = {
  loop: true,
  play: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
/*
Show the survey if
  The customer did not dismiss the survey in the last 7 days.
  The customer did not complete the survey in the last 180 days.
*/
function showSurvery(
  profilePreferences: ProfilePreferencesType | undefined
): boolean {
  if (profilePreferences) {
    const lastDismiss: Date | null =
      profilePreferences.customer_satisfaction_survey_last_dismissed_at
        ? TimezoneUtils.zonedDate(
            profilePreferences.customer_satisfaction_survey_last_dismissed_at
          )
        : null;
    const lastCompletion: Date | null =
      profilePreferences.customer_satisfaction_survey_last_completed_at
        ? TimezoneUtils.zonedDate(
            profilePreferences.customer_satisfaction_survey_last_completed_at
          )
        : null;
    const today = TimezoneUtils.zonedDate();

    if (lastDismiss && lastCompletion) {
      if (differenceInDays(lastCompletion, lastDismiss) >= 0) {
        return differenceInDays(today, lastCompletion) > 180;
      } else {
        return differenceInDays(today, lastDismiss) > 7;
      }
    }

    if (lastCompletion && !lastDismiss) {
      return differenceInDays(today, lastCompletion) > 180;
    }

    if (lastDismiss && !lastCompletion) {
      return differenceInDays(today, lastDismiss) > 7;
    }

    //!lastDismiss && !lastCompletion
    return true;
  } else {
    return false;
  }
}

export default function ProfilePreferencesRatingSnackbar() {
  const { data: profilePreferences } = useSWR<ProfilePreferencesType>(
    formBackendURL("/profile/preferences/"),
    fetcher
  );

  const { captureEvent } = useEvents();

  const [open, setOpen] = useState(true);
  const [thankYouSnackbarOpen, setThankYouSnackbarOpen] = useState(false);
  const [trustPilotModalOpen, setTrustPilotModalOpen] = useState(false);

  function onRate(_value: number, label: string) {
    // Pushing event to Posthog with score
    captureEvent(PostHogEvents.CustomerSatisfactionSurveyCompleted, {
      "Survey response": label,
    });
    patchPreferences({
      id: profilePreferences?.id,
      customer_satisfaction_survey_last_completed_at:
        TimezoneUtils.zonedDate().toISOString(),
    });

    setOpen(false);

    if (label === "Strongly recommend") {
      setTrustPilotModalOpen(true);
    } else {
      // the default.
      setThankYouSnackbarOpen(true);
    }
  }

  function onDismiss() {
    setOpen(false);
    patchPreferences({
      id: profilePreferences?.id,
      customer_satisfaction_survey_last_dismissed_at:
        TimezoneUtils.zonedDate().toISOString(),
    });

    captureEvent(PostHogEvents.CustomerSatisfactionSurveyDismissed);

    //Don't say thank you after dismiss
    setThankYouSnackbarOpen(false);
  }

  const isRatingOpen = open && showSurvery(profilePreferences);

  useEffect((): void => {
    if (isRatingOpen) {
      captureEvent(PostHogEvents.CustomerSatisfactionSurveyDisplayed);
    }
  }, [isRatingOpen, captureEvent]);

  return (
    <>
      <RatingSnackbar
        open={isRatingOpen}
        onRate={onRate}
        onDismiss={onDismiss}
      />
      <ThankYouSnackbar
        open={thankYouSnackbarOpen}
        setOpen={setThankYouSnackbarOpen}
      />
      <InfoCardDialog
        onClose={() => setTrustPilotModalOpen(false)}
        open={trustPilotModalOpen}
        lottieOptions={lottieOptions}
        title="Thank you for helping us improve!"
        description="Would you leave us a review on Trustpilot?"
      >
        <Button
          variant="text"
          size="large"
          color="primary"
          onClick={() => setTrustPilotModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          data-testid="trustpilot-cta"
          variant="contained"
          size="large"
          color="primary"
          href="https://www.trustpilot.com/review/webshare.io"
        >
          Yes, Write Review
        </Button>
      </InfoCardDialog>
    </>
  );
}
