import React, { forwardRef } from "react";
import { Box, BoxProps } from "@mui/material";

type InsetProps = {
  padding: number;
} & BoxProps;

const Inset = forwardRef<HTMLDivElement, InsetProps>(
  ({ padding, ...rest }, ref) => {
    return <Box ref={ref} padding={padding} {...rest} />;
  }
);
Inset.displayName = "Inset";

export default Inset;
