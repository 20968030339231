import Box from "@mui/material/Box";
import { styled } from "@mui/material";

export const StatsHolder = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  [theme.breakpoints.up("lg")]: {
    display: "block",
  },
  [theme.breakpoints.up("xl")]: {
    alignItems: "center",
    display: "flex",
  },
}));

export const ContentHolder = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.up("xl")]: {
    marginLeft: theme.spacing(3),
  },
}));
