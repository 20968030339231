import { Box, Typography, Stack } from "@mui/material";
import { StyledCard } from "./styles";
import { useSession } from "../../security/RouteGuards";

import HelpArticle from "../buttons/HelpArticle";
import { Highlighter } from "@/ui/Highlighter";
import simplur from "simplur";
import { useMemo } from "react";
import useSWR from "swr";
import { formBackendURL } from "@/utils/formURL";
import { fetcher } from "@/utils/fetcher";
import { ProxyConfigurationType } from "@/types/ProxyConfigurationTypes";
import makeSubTypeLabel from "@/utils/makeSubTypeLabel";
import { fixPlanValues } from "../subscription/subscriptionHelpers";

export default function PlanMini() {
  const { plan: sessionPlan } = useSession();

  const plan = fixPlanValues({
    sessionPlan,
  });

  const isResidential =
    plan?.proxy_type === "shared" && plan?.proxy_subtype === "residential";

  const planDisplayName =
    plan && makeSubTypeLabel({ plan, prependProxyCount: true });

  const { data: proxyConfigurationResponse } = useSWR<ProxyConfigurationType>(
    formBackendURL(`/proxy/config/`),
    fetcher
  );

  const proxyCount = useMemo(() => {
    if (!proxyConfigurationResponse?.countries) return 0;
    return Object.keys(proxyConfigurationResponse.countries).length ?? 0;
  }, [proxyConfigurationResponse]);

  return (
    <StyledCard>
      <Stack
        direction={"row"}
        width={1}
        spacing={2}
        padding={1}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack direction={"column"} width={1} spacing={1}>
          <Stack direction={"row"} width={1} spacing={2}>
            <Typography variant="h6" color="text.primary">
              Proxy Plan
            </Typography>
            <Box>
              <Highlighter
                size="medium"
                innerVariant="opacity"
                severity="info"
                textTransform="uppercase"
              >
                {isResidential
                  ? `${makeSubTypeLabel({
                      plan,
                      prependProxyCount: false,
                    })} (${Math.round(plan?.proxy_count / 1e6)}m+ IPs/month)`
                  : planDisplayName}
              </Highlighter>
            </Box>
          </Stack>

          <Typography
            variant="caption"
            color="text.secondary"
            id="countriesTypography"
          >
            {simplur`Your prox[y|ies] are from ${proxyCount} countr[y|ies]`}
          </Typography>
        </Stack>
        <HelpArticle id="proxyPlan" />
      </Stack>
    </StyledCard>
  );
}
