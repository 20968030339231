import {
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import MostPopularPlansModal from "../modals/MostPopularPlans/MostPopularPlansModal";
import { useState } from "react";
import useModalTracking from "@/hooks/useModalTracking";

export default function MostPopularPlans() {
  const [open, setOpen] = useState(false);

  const trackOpen = useModalTracking({
    modalName: "Most Popular Plans New CTA",
  });

  const theme = useTheme();
  const isUpFromSm = useMediaQuery(theme.breakpoints.up("sm"));

  const onClickAction = () => {
    setOpen(true);
    trackOpen();
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
      >
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Image
            alt={"image"}
            src={`/best-seller.svg`}
            width={40}
            height={40}
          />
          <Stack>
            <Typography variant="subtitle1">
              Try One of Our Most Popular Plans
            </Typography>
            <Typography variant="caption">Starting from $2.99</Typography>
          </Stack>
        </Stack>
        <Button
          fullWidth={!isUpFromSm}
          disableElevation
          size="small"
          variant="contained"
          onClick={onClickAction}
        >
          Try now
        </Button>
      </Stack>
      <MostPopularPlansModal open={open} onClose={() => setOpen(false)} />
    </Card>
  );
}
