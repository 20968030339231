import {
  IconButton,
  Snackbar,
  Typography,
  Card,
  useTheme,
  ThemeProvider,
  CardActions,
  Stack,
} from "@mui/material";
import { useAppTheme } from "../theme";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleFilled from "@mui/icons-material/CheckCircleOutlined";

interface ThankYouSnackbarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ThankYouSnackbar({
  open,
  setOpen,
}: ThankYouSnackbarProps) {
  const { palette } = useTheme();

  const isDarkMode = palette.mode === "dark";

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={useAppTheme(isDarkMode ? "light" : "dark")}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Card data-testid="thank-you-snackbar-card">
          <CardActions>
            <Stack direction="row" alignItems="center" spacing={2} padding={2}>
              <CheckCircleFilled color="primary" />
              <Stack>
                <Typography variant="body1" sx={{ pl: 1, pr: 3 }}>
                  Thank you for helping us improve!
                </Typography>
              </Stack>
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            </Stack>
          </CardActions>
        </Card>
      </Snackbar>
    </ThemeProvider>
  );
}
