import useIsFlagVariantTest from "@/hooks/useIsFlagVariantTest";
import { useRouter } from "next/router";

export default function useUpgradeToReplaceExp() {
  const inExp = useIsFlagVariantTest("replacement_upgrade_flow");

  const { query } = useRouter();

  const navigatedFromUpgradeToRepalceModal =
    query.source === "upgrade-to-replace";

  return inExp && navigatedFromUpgradeToRepalceModal;
}
